// export const mainImageData = graphql`
//   fragment mainImageData on File {
//     childImageSharp {
//       fluid(maxWidth: 1080) {
//         ...GatsbyImageSharpFluid

//       }
//     }
//   }
// `;
import { useStaticQuery, graphql } from "gatsby";

export const smallImageData = graphql`
  fragment smallImageData on File {
    childImageSharp {
      gatsbyImageData(
        height: 50
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;
export const smallImageTracedData = graphql`
  fragment smallImageTracedData on File {
    childImageSharp {
      gatsbyImageData(
        height: 50
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;
export const mainImageData = graphql`
  fragment mainImageData on File {
    childImageSharp {
      gatsbyImageData(
        width: 1080
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
`;

export const useLayoutImages = () => {
    return useStaticQuery(graphql`
    query {
      layoutImages: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "backgrounds" }
        }
      ) {
        nodes {
          name
          ...mainImageData
        }
      }
      navLogo: file(
        relativePath: { eq: "logo/white-compact.png" }
        sourceInstanceName: { eq: "images" }
      ) {
        ...smallImageTracedData
      }
    }
  `);
};