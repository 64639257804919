import React from "react";
import Navbar from "../nav/Navbar";
import Seo from "./seo";
import constants from "../../utils/constants";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLayoutImages } from "../../utils/queries";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { SnackbarProvider } from 'notistack';
import { StateProvider } from "../../StateProvider";
import reducer, { initialState } from "../../reducer";

const LayoutWrapper = ({ ssr = false, children }) => {
    // if (ssr) return children;
    return <StateProvider initialState={initialState} reducer={reducer}><SnackbarProvider>{children}</SnackbarProvider></StateProvider>
}

const Layout = ({ children, path, ssr = false }) => {
    const [page, setPage] = React.useState(null);
    const { layoutImages } = useLayoutImages();
    const [layoutImage, setLayoutImage] = React.useState(null);
    // console.log(layoutImages);
    React.useEffect(() => {
        const pa =
            path.length > 1 && path.slice(-1) === "/" ? path.slice(0, -1) : path;
        const p = constants.seo[pa];
        // console.log(p, pa, constants);
        setPage(p);
        setLayoutImage(
            p?.layoutImage &&
            layoutImages &&
            layoutImages.nodes.find((i) => i.name === p.layoutImage)
        );
    }, [path]);
    const homeLayout = !path.startsWith("/dashboard") && !path.startsWith("/website_template")
    return (
        <LayoutWrapper>
            <a
                href="#main-content"
                style={{
                    position: "absolute",
                    transform: "translateY(-120%)",
                    transition: "all .3s",
                    border: "1px solid #09f",
                    top: 5,
                    left: 5,
                    backgroundColor: "#fff",
                    borderRadius: 5,
                    zIndex: 9999,
                    padding: 3,
                }}
                title="Skip Navigation"
            >
                Skip Navigation
            </a>
            {homeLayout && <Navbar path={path} isBrowser={!ssr} />}
            <Seo />
            <main id="main-content">
                {(homeLayout && (layoutImage || page?.displayTitle || page?.displayComponent)) && (
                    <Box sx={{ mb: 8, position: "relative", maxWidth: "sm", mx: "auto" }} component="section">
                        {layoutImage && (
                            <GatsbyImage
                                image={getImage(layoutImage)}
                                alt={page.layoutImageAlt ?? page.layoutImage}
                                style={{ width: "100%", maxHeight: "92vh" }}
                            />
                        )}
                        {page?.displayTitle && (
                            <Box
                                sx={{ position: "absolute", top: 0, pt: 15, left: 0, right: 0 }}
                            >
                                <Typography
                                    component="h2"
                                    variant="h3"
                                    align="center"
                                    sx={{ fontWeight: "bold", pb: 3 }}
                                >
                                    {page.displayTitle}
                                </Typography>
                                <Typography align="center">{page.displaySubtitle}</Typography>
                            </Box>
                        )}
                    </Box>
                )}
                {children}
            </main>
            <footer></footer>
            <div
                style={{ position: "absolute", top: 0 }}
                id={constants.topAnchorId}
            ></div>
        </LayoutWrapper>
    );
};

export default Layout;