import React from "react";
import ScrollTop from "./ScrollTop";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { IconButton } from "gatsby-theme-material-ui";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
// import MenuItem from "@mui/material/MenuItem";
import constants from "../../utils/constants";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useLayoutImages } from "../../utils/queries";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import DragHandleRoundedIcon from "@mui/icons-material/DragHandleRounded";
import { useScrollPosition } from "../../hooks/useScrollPosition";
import NavDrawer from "./NavDrawer";
import "./navbar.css";

const navMobileBreakPoint = "md";
const NavLink = ({ page: { to, text, title }, sx = {} }) => {
  return (
    <Box sx={{ mx: 3, ...sx }}>
      <AnchorLink to={to} title={title} className="nav__link">
        {text}
      </AnchorLink>
    </Box>
  );
};

const Navbar = ({ path, isBrowser }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleCloseNavMenu = () => {
    setDrawerOpen(false);
  };
  const handleOpenNavMenu = () => {
    setDrawerOpen(true);
  };
  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open ?? !drawerOpen);
  };
  // const { navLogo } = useLayoutImages();
  const [useScrollNav, setUseScrollNav] = React.useState(isBrowser);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      console.log(prevPos, currPos);
      setUseScrollNav(currPos.y < -100);
      // const isShow = currPos.y > prevPos.y;
      // if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [useScrollNav]
  );
  //todo const logo = getImage(navLogo)
  const logo = null;
  return (
    <>
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        // dense={useScrollNav}
        className={`nav__background${
          useScrollNav ? " nav__background__scroll" : ""
        }`}
      >
        <Toolbar
          variant={useScrollNav ? "dense" : null}
          disableGutters
          sx={{
            py: useScrollNav ? "1px" : 2,
            px: 2,
            transition: "all .3s",
            // background: useScrollNav ? "rgba(34,34,34,1)" : "rgba(34,34,34,0)",
            // ...(useScrollNav ? { maxHeight: 64 } : {}),
          }}
        >
          <Container disableGutters maxWidth="xl" sx={{ display: "flex" }}>
            <Box sx={{ display: { [navMobileBreakPoint]: "none" } }}>
              <IconButton
                size="large"
                // sx={{ color: "#ffffff" }}
                aria-label="navigation button"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Container
              maxWidth="xl"
              disableGutters
              sx={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {constants.navLinks.map((page, index) => (
                <React.Fragment key={index}>
                  {Math.trunc(constants.navLinks.length / 2) === index && (
                    <Box
                      sx={{
                        display: "flex",
                        // flexGrow: 1,
                        mx: 3,
                        justifyContent: "center",
                        "& .gatsby-image-wrapper": {
                          maxWidth: useScrollNav ? 240 : 280,
                          transition: "all .3s",
                        },
                      }}
                    >
                      {/* <Img fluid={navImages.navLogo.childImageSharp.fluid} alt="SMFS" /> */}
                      {logo ? (
                        <GatsbyImage image={logo} alt="Agency Manager" />
                      ) : (
                        <Typography
                          component="h1"
                          sx={{ fontWeight: "bold" }}
                          variant="h5"
                        >
                          Agency Manager
                        </Typography>
                      )}
                    </Box>
                  )}
                  <NavLink
                    sx={{
                      display: page.extendedMenu
                        ? { xs: "none", xl: "flex" }
                        : { xs: "none", [navMobileBreakPoint]: "flex" },
                    }}
                    page={page}
                    key={page.to}
                  />
                </React.Fragment>
              ))}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    [navMobileBreakPoint]: "flex",
                    xl: "none",
                  },
                  // "& .gatsby-image-wrapper > div": {},
                }}
              >
                <IconButton
                  size="large"
                  sx={{ color: "#ffffff" }}
                  aria-label="navigation button"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <DragHandleRoundedIcon />
                </IconButton>
              </Box>
            </Container>
          </Container>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      <ScrollTop />
      {isBrowser && (
        <NavDrawer
          toggleDrawer={toggleDrawer}
          navMobileBreakPoint={navMobileBreakPoint}
          drawerOpen={drawerOpen}
        />
      )}
    </>
  );
};

export default Navbar;
