import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif'
        ].join(','),
    },
    // palette: {
    //     // type: "dark",
    //     primary: {
    //         main: "#50c878",
    //         contrastText: "#ffffff",
    //     },
    //     secondary: {
    //         main: "#f50057",
    //     },
    // },
});

export default theme;    // 