import React from "react";
import { Fab } from "gatsby-theme-material-ui";
import { Zoom } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import constants from "../../utils/constants";

const ScrollTop = () => {
    const showScrollTop = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    return (
        <Zoom in={showScrollTop}>
            <div
                role="presentation"
                style={{ position: "fixed", bottom: 16, right: 16 }}
            >
                <AnchorLink
                    to={constants.genTopAnchorLink()}
                    // stripHash={true}
                    title="Back to Top"
                >
                    <Fab color="primary" size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpRoundedIcon />
                    </Fab>
                </AnchorLink>
            </div>
        </Zoom>
    );
};

export default ScrollTop;