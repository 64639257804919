import fetch from 'isomorphic-fetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from "apollo-upload-client";
import constants from "../utils/constants";
import { setContext } from "@apollo/client/link/context";
import { getStorageToken } from "../services/auth"
const httpLink = createUploadLink({
    uri: constants.gqlUri,
    credentials: "include",
    fetch
});
const authLink = setContext((_, { headers }) => {
    const token = getStorageToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : "",
        },
    };
});
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink)
});

export default client;