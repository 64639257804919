import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import constants from "../../utils/constants";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import { Link } from "gatsby"
const borderRad = 12;


const LinkAnchorLink = ({ ref, ...props }) => {
    console.log(props)
    return <AnchorLink {...props} />
}


const NavDrawer = ({ toggleDrawer, drawerOpen, navMobileBreakPoint }) => {
    const isMobile = useMediaQuery((theme) =>
        theme.breakpoints.down(navMobileBreakPoint)
    );
    const anchor = isMobile ? "left" : "right";
    const borderProps = {
        [`borderTop${anchor === "left" ? "right" : "left"}Radius`]: borderRad,
        [`borderBottom${anchor === "left" ? "right" : "left"}Radius`]: borderRad,
    };
    return (
        <SwipeableDrawer
            PaperProps={{
                sx: { ...borderProps },
            }}
            aria-label="menu-appbar"
            anchor={anchor}
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            disableSwipeToOpen={false}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <List>
                {constants.navLinks.map((page) => (
                    <ListItem
                        key={page.to}
                        disablePadding
                        sx={
                            page?.extendedMenu
                                ? { xs: "none", [navMobileBreakPoint]: "flex" }
                                : { xs: "flex", [navMobileBreakPoint]: "none" }
                        }
                    >
                        <ListItemButton
                            // component={AnchorLink}
                            component={Link}
                            onClick={toggleDrawer(false)}
                            to={page.to}
                            key={page.to}
                            title={page.title}
                            className={"nav__link__hover"}
                        >
                            <ListItemText primary={page.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </SwipeableDrawer>
    );
};

export default NavDrawer;