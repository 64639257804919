import constants from "../utils/constants"

const logoutClearDataStorage = () => {
    localStorage.removeItem(constants.storageItemKeys.token);
    localStorage.removeItem(constants.storageItemKeys.refreshToken);
    localStorage.removeItem(constants.storageItemKeys.keepMeLoggedIn);
};


const setStorageTokens = ({ token = null, refreshToken = null, keepMeLoggedIn = false }) => {
    keepMeLoggedIn &&
        localStorage.setItem(constants.storageItemKeys.keepMeLoggedIn, "0");
    token && localStorage.setItem(constants.storageItemKeys.token, token)
    refreshToken && localStorage.setItem(constants.storageItemKeys.refreshToken, refreshToken)
}
const getStorageRefreshToken = () => localStorage.getItem(
    constants.storageItemKeys.refreshToken
)
const getStorageToken = () => localStorage.getItem(
    constants.storageItemKeys.token
)
const getStorageKeepMeLoggedIn = () => localStorage.getItem(
    constants.storageItemKeys.keepMeLoggedIn
) === "0"

export { logoutClearDataStorage, setStorageTokens, getStorageRefreshToken, getStorageKeepMeLoggedIn, getStorageToken };