import constants from "./utils/constants";

export const initialState = {
  user: null,
  agency: null,
  users: [],
  courses: [],
  courseUsers: [],
  notifications: [],
  alerts: [],
  mainSideOpen: false,
  authData: {
    refreshToken: null,
    token: null,
    keepMeLoggedIn: false,
  },
};

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_ALL_USERS: "SET_ALL_USERS",
  SET_COURSES: "SET_COURSES",
  SET_COURSE_USERS: "SET_COURSE_USERS",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_ALERTS: "SET_ALERTS",
  SET_MAIN_SIDE_OPEN: "SET_MAIN_SIDE_OPEN",
  SET_AUTH_DATA: "SET_AUTH_DATA",
  SET_USER_AGENCY: "SET_USER_AGENCY",
};

const reducer = (state, action) => {
  constants.dev && console.log(action);
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SET_USER_AGENCY:
      return {
        ...state,
        user: { ...state.user, agency: action.agency },
      };
    case actionTypes.SET_ALL_USERS:
      return {
        ...state,
        users: action.users,
      };
    case actionTypes.SET_COURSES:
      return {
        ...state,
        courses: action.courses,
      };
    case actionTypes.SET_COURSE_USERS:
      return {
        ...state,
        courseUsers: action.courseUsers,
      };
    case actionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
      };
    case actionTypes.ALERTS:
      return {
        ...state,
        alerts: action.alerts,
      };
    case actionTypes.SET_MAIN_SIDE_OPEN:
      return {
        ...state,
        mainSideOpen: action.mainSideOpen,
      };
    case actionTypes.SET_AUTH_DATA:
      return {
        ...state,
        authData: { ...state.authData, ...action.authData },
      };
    default:
      return state;
  }
};

export default reducer;
