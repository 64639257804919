const topAnchorId = "back-to-top-anchor";
const isDev = process.env.GATSBY_RUNTIME === "development";
const uri = isDev
  ? process.env.GATSBY_API_URL ?? "http://127.0.0.1:8062"
  : "https://api.marketceptionacademy.com";
const pages = [
  {
    to: "/",
    // text:"Contact Us"
    // linkDisplay:
    // displayTitle: "About Us",
    title: "Home",
    description: "",
    // layoutImage: "cars",
    displayTitle: "Welcome to the Agency Manager",
    displaySubtitle:
      "The best in its class. Setting the pace for what it takes to be an all in one platform ",
    // displayComponent:,
  },
  { to: "/dashboard/", title: "Dashboard" },
];
const storageItemKeys = {
  token: "gkgngrenuoirengui34niuogno5rng",
  refreshToken: "gnreng34iogn43orj3r3rk0k0k0k0kr",
  kml: "gmreogi34kfg43fkg34f439ot3l0flflflflflkkmeflfe2wo23wm",
};
const storageItemKeysDev = {
  token: "token",
  refreshToken: "refreshToken",
  kml: "KeepMeLoggedIn",
};
const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

const constants = {
  // Api Data
  uri: uri,
  gqlUri: `${uri}/graphql`,
  formatMediaUrl: (url) => `${uri}/media/${url}`,
  storageItemKeys: isDev ? storageItemKeysDev : storageItemKeys,
  tokenRefreshInterval: 200,
  logInFieldRequired: "agencyManagerAccess", // GQL Key for field that needs ot be present to access, leave empty to ignore check
  // logInFieldRequiredMessage: "Please purchase the course", "Please purchase the Portal to Login!",
  forgotPasswordParams: {
    userId: "ruinfasdfgb",
    urlCode: "cvjdfsknvbiouernv",
  },
  // Nav Data
  navLinks: pages.map((page) => ({
    to: page.to,
    title: page.title,
    text: page.linkDisplay ?? page.title,
    extendedMenu: [
      //   "/muslim-funeral-guide",
      //   "/education-workshop",
      //   "/advice-support",
    ].includes(page.to),
  })),
  navbarOffset: -64,
  navPadding: 64,
  seo: Object.assign({}, ...pages.map(({ to, ...x }) => ({ [to]: x }))),
  topAnchorId,
  genTopAnchorLink: (path) => `${path ?? ""}#${topAnchorId}`,
  dev: isDev,
  iOS,
  agencyName: "Hoi Agency",
  agencyDomain: "agency.halalonlineincome.com",
};

module.exports = constants;
// export { constants as default };
